<!-- © 2021 Tfarraj -->
<template>
  <v-container fluid class="fill-height">
    <v-row>
      <v-col cols="12" xs="12" sm="6" md="5" lg="4" class="ma-auto">
        <v-card outlined :loading="loading">
          <div class="px-8 pt-6 pb-12">
            <v-card-title class="text-center"
              ><img class="responsive-img" src="/logo.svg" :alt="siteName"
            /></v-card-title>
            <v-card-subtitle class="pt-6 pb-6">{{
              confirmationStatus
            }}</v-card-subtitle>
            <v-btn
              type="submit"
              class="primary"
              :loading="loading"
              depressed
              tabindex="3"
              to="/"
              >{{ $t('confirmEmail.goHome') }}
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Config from '@/config';
import UserService from '@/services/UserService';

export default {
  name: 'ConfirmEmail',
  data() {
    return {
      siteName: Config.siteName,
      confirmationStatus: this.$i18n.t('confirmEmail.confirmEmailLoading'),
      loading: false,
    };
  },
  methods: {
    async confirmEmail() {
      this.loading = true;

      try {
        const token = { token: this.$route.query.token };
        await UserService.confirmEmailUpdate(token);
        await this.$store.dispatch('getUser');
        this.confirmationStatus = this.$i18n.t(
          'confirmEmail.confirmEmailSuccess',
        );
      } catch (e) {
        const errors = e.response.data ? e.response.data.error : [];
        const emailValidationError = errors.find(
          (error) => error.field === 'email',
        )
          ? errors.find((error) => error.field === 'email')
          : [];
        if (emailValidationError.length !== 0) {
          this.confirmationStatus = this.$i18n.t('confirmEmail.duplicateEmail');
        } else {
          this.confirmationStatus = this.$i18n.t(
            'confirmEmail.confirmEmailFailed',
          );
        }
      } finally {
        this.loading = false;
      }
    },
  },
  beforeMount() {
    this.confirmEmail();
  },
};
</script>

<style></style>
