<!-- © 2021 Tfarraj -->
<template>
  <v-container fluid class="fill-height">
    <v-row>
      <v-col cols="12" xs="12" sm="6" md="5" lg="4" class="ma-auto">
        <v-card outlined :loading="loading">
          <div class="px-8 pt-6 pb-12">
            <v-card-title class="text-center"
              ><img class="responsive-img" src="/logo.svg" :alt="siteName"
            /></v-card-title>
            <v-card-subtitle class="mb-5">{{
              activationStatus
            }}</v-card-subtitle>
            <v-btn
              type="submit"
              class="primary"
              :loading="loading"
              depressed
              tabindex="3"
              to="/"
              >{{ $t('activation.goHome') }}
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Config from '@/config';
import UserService from '@/services/UserService';

export default {
  name: 'Activation',
  data() {
    return {
      siteName: Config.siteName,
      activationStatus: this.$i18n.t('activation.activationLoading'),
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['getIsUserActive']),
  },
  methods: {
    async activate() {
      this.loading = true;
      try {
        const token = { token: this.$route.query.token };
        await UserService.activate(token);
        this.activationStatus = this.$i18n.t('activation.activationSuccess');
        await this.$store.dispatch('retrieveUser');
        this.resetRequestStatus = true;
      } catch (e) {
        if (this.getIsUserActive)
          this.activationStatus = this.$i18n.t('activation.alreadyActivated');
        else
          this.activationStatus = this.$i18n.t('activation.activationFailed');
      } finally {
        this.loading = false;
      }
    },
  },
  beforeMount() {
    this.activate();
  },
};
</script>

<style></style>
